<i18n>
ru:
  title: Успей купить!
  description: Вы можете приобрести только одно блюдо из блока за один заказ
  timerName: 'До конца акции:'
  btnText: 'Добавить в корзину за '
ua:
  title: Встигни купити!
  description: Ви можете придбати тільки одне блюдо з блоку за одне замовлення
  timerName: 'До кінця акції:'
  btnText: 'Додати до кошику за '
us:
  title: Limited offer!
  description: You can order only one item at same time from here
  timerName: 'Until the end of the promotion:'
  btnText: 'Add to cart for '
</i18n>

<template>
  <common-skeleton
    v-if="!discountsData"
    :height="280"
    :loaders-in-row="appConfig.VueSettingsPreRun.DiscountTimerProductsMax"
  />

  <menu-discount-timers-max-one
    v-else-if="discountsData.length === 1"
    :group-id="groupId"
  />
  <menu-discount-timers-more-than-one
    v-else-if="discountsData.length > 1"
    :group-id="groupId"
  />
</template>

<script setup lang="ts">
import type { EmitterEvent } from '~types/common'
import type { CurrentDiscount } from '~types/menuStore'

import type { GUID } from '@arora/common'

const { groupId = null } = defineProps<{
  groupId?: GUID | null
}>()

const menuStore = useMenuStore()

const { eventOn, eventOff } = useEmitter()
const appConfig = useAppConfig()

const discountsData = computed<CurrentDiscount[]>(() => {
  return menuStore.DiscountTimers?.data?.CurrentDiscounts ?? []
})

const timerEvent: EmitterEvent = 'v-discount-timer'

onMounted(() => {
  menuStore.initDiscountTimers(groupId)

  eventOn(timerEvent, onTimerEndFunction)
})

onUnmounted(() => {
  eventOff(timerEvent, onTimerEndFunction)
})

let timerLock = true

function onTimerEndFunction(): void {
  if (timerLock) {
    setTimeout(() => {
      timerLock = false
      menuStore.loadDiscountTimers(groupId).then(() => (timerLock = true))
    }, 750)
  }
}
</script>

<style lang="scss">
.v-old {
  margin-right: 8px;
}

.v-discount-timer-aggregator {
  flex: 0 0 100%;
  max-width: 100%;
  text-align: center;
}

.v-price-block {
  text-align: right;

  .v-btn {
    display: flex;
  }
}
</style>
