<template>
  <ClientOnly>
    <div class="v-menu-banner-wrapper">
      <arora-swiper-slider
        loop
        :autoplay-delay="5000"
        :items="banners"
        :max-items="1"
        :show-part-of-next-slide="false"
        :space-between="5"
        pagination-type="none"
      >
        <template #item="item: BannerInList">
          <arora-nuxt-link
            v-if="item.CustomHref"
            class-name="v-menu-banner v-pointer"
            open-in-new-tab
            :href="item.CustomHref"
            :label="item.Title ?? ''"
          >
            <arora-nuxt-image
              v-if="isExtraSmall && imageExists(item.DesktopMobileImage)"
              :key="`${item.ID}-small`"
              :alt="item?.Title ?? ''"
              :image="item.DesktopMobileImage"
            />
            <arora-nuxt-image
              v-else
              :key="`${item.ID}-big`"
              disable-lazy
              disable-resize
              :alt="item?.Title ?? ''"
              :image="item.DesktopImage"
            />
          </arora-nuxt-link>
          <div
            v-else
            class="v-menu-banner"
          >
            <arora-nuxt-image
              v-if="isExtraSmall && imageExists(item.DesktopMobileImage)"
              :key="`${item.ID}-small`"
              disable-lazy
              :alt="item?.Title ?? ''"
              :image="item.DesktopMobileImage"
            />
            <arora-nuxt-image
              v-else
              :key="`${item.ID}-big`"
              disable-lazy
              :alt="item?.Title ?? ''"
              :image="item.DesktopImage"
            />
          </div>
        </template>
      </arora-swiper-slider>
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
import type { BannerInList } from '~types/menuStore'

import { useWindowSize } from '@arora/common'

defineProps<{
  banners: BannerInList[]
}>()

const { isExtraSmall } = useWindowSize()
const { imageExists } = useImageInfo()
</script>

<style lang="scss">
@use '~/assets/variables';

.v-menu-banner-wrapper {
  max-width: 100%;

  .v-swiper .swiper-wrapper {
    margin: 0;
    border-radius: variables.$BorderRadius;
    padding: 0;
    height: 100%;

    .v-menu-banner {
      height: 100%;
    }

    .v-img-fluid {
      border-radius: variables.$BorderRadius;
      object-fit: cover;
    }
  }
}
</style>
